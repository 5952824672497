import React from 'react';

const Slider = ({id = 'slider', label, value, onChange}) => {
  return (
    <div className="relative p-6">
      <label htmlFor={`slider-${id}`} className="form-label">
        {label}: {value}
      </label>
      <input
        type="range"
        min="0"
        max="100"
        step={20} //opacity-0 opacity-20 opacity-40 opacity-60 opacity-80 opacity-100
        onChange={onChange}
        className="
                form-range
                h-2
                w-full
                appearance-none
                rounded-3xl
                border
                bg-gray-200
                p-0
                focus:shadow-none focus:outline-none focus:ring-0
        "
        id={`slider-${id}`}
      />
    </div>
  );
};

export default Slider;
