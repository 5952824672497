import React from 'react';

const Checkbox = ({id, name, label, onClick, checked}) => {
  return (
    <div className="flex items-start m-4">
      <div className="flex items-center h-5">
        <input
          id={id}
          aria-describedby={`${name}-description`}
          name={name}
          type="checkbox"
          className="focus:ring-rose-400 h-4 w-4 text-rose-300 border-gray-300 rounded"
          checked={checked}
          onChange={onClick}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={id} className="font-medium text-gray-700">
          {label}
        </label>
      </div>
    </div>
  );
};

Checkbox.propTypes = {

};

export default Checkbox;