import React from "react";
import {HashRouter, Route, Switch} from "react-router-dom";
import App from "./App";
import Home from './components/Home';

export default (
    <HashRouter>
        <App>
            <Switch>
                <Route exact path="/" component={Home}/>
            </Switch>
        </App>
    </HashRouter>
);
