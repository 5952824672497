import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import routes from './routes';


ReactDOM.render(
  <React.StrictMode>
    {routes}
  </React.StrictMode>,
  document.getElementById('root')
);
