export const defaultConfig = {
  "editorMode": true,
  "layout": {
    "width": 1800,
    "height": 1200,
    "isLegacyDesignType": false,
    "slices": {
      "quantity": 1,
      "direction": "row",
      "divider": {
        "lineWidth": 4,
        "color": "#FF00FF",
        "lineDash": {
          "thickness": 10,
          "gap": 10
        }
      }
    },
    "limiter": {
      "x": 55,
      "y": 45,
      "lineWidth": 2,
      "color": "#FF00FF",
      "width": 1690,
      "height": 1110,
      "lineDash": {
        "thickness": 10,
        "gap": 10
      }
    },
    "photos": [
      {
        "x": 500,
        "y": 430,
        "width": 926,
        "rotation": 0,
        "shadowBlur": 20,
        "borderColor": "#ffffff",
        "borderWidth": 10,
        "shadowColor": "#000000",
        "shadowOpacity": 0.5,
        "src": "/media/image-1.jpg"
      },
      {
        "x": 1280,
        "y": 570,
        "width": 926,
        "rotation": 0,
        "shadowBlur": 20,
        "borderColor": "#ffffff",
        "borderWidth": 10,
        "shadowColor": "#000000",
        "shadowOpacity": 0.5,
        "src": "/media/image-2.jpg"
      }
    ],
    "printerPageSize": "w288h432",
    "texts": [
      {
        "x": 900,
        "y": 1050,
        "fill": "#000000",
        "fontSize": 70,
        "rotation": 0,
        "fontFamily": "PT Sans",
        "fontAlignment": "center",
        "content": "Test & Test",
        "fontStyle": "normal"
      },
      {
        "x": 900,
        "y": 1112,
        "fill": "#000000",
        "fontSize": 50,
        "rotation": 0,
        "fontFamily": "PT Sans",
        "fontAlignment": "center",
        "content": "2022.04.25",
        "fontStyle": "normal"
      }
    ],
    "groundImages": {
      "backgroundImage": "/media/background-2.jpg",
      "foregroundImage": "",
      "orientation": "landscape"
    }
  }
}