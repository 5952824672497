import React, {useEffect, useState, Fragment} from 'react';
import JsonEditor from './JsonEditor';
import KruuCollage from '@kruu/kruu-collage';
import KruuCollage2 from '@kruu/fotobox-collagen';
import {landscape2p15x10} from '../configs/landscape2p15x10';
import {portrait3p10x75div2} from '../configs/portrait3p10x75div2';
import {squared} from '../configs/squared';
import {landscape3p5x10div3} from '../configs/landscape3p5x10div3';
import {einzel} from '../configs/einzel';
import {legacy3p} from '../configs/legacy3p';
import Checkbox from './Checkbox';
import Header from './Header';
import Dropdown from './Drowpdown';
import {generateLayoutForLegacyKruuCollage} from '../utils';
import {defaultConfig} from '../configs/default';
import Alert from './Alert';
import Slider from './Slider';

export default function Home() {
  const [selectedConf, setSelectedConf] = useState(defaultConfig);
  const [newConfig, setNewConfig] = useState(selectedConf);
  const [displayNewCollage, setDisplayNewCollage] = useState(true);
  const [displayOldCollageBellow, setDisplayOldCollageBellow] = useState(true);
  const [displayOldCollageOver, setDisplayOldCollageOver] = useState(false);
  const [designTypes, setDesignTypes] = useState([]);
  const [selectedDesignType, setSelectedDesignType] = useState();
  const [error, setError] = useState();
  const [opacity, setOpacity] = useState(40);

  useEffect(() => {
    if (selectedDesignType && selectedDesignType.id) {
      let newObj = {
        ...defaultConfig,
        id: selectedDesignType.id,
        name: selectedDesignType.name,
      };
      if (selectedDesignType.designConfig && selectedDesignType.designConfig) {
        newObj.layout = selectedDesignType.designConfig;
        if (newObj.layout.photos && newObj.layout.photos.length > 0) {
          newObj.layout.photos = newObj.layout.photos.map((p, index) => ({
            ...p,
            src: `/media/image-${index + 1}.jpg`,
          }));
        }

        if (newObj.layout.texts && newObj.layout.texts.length > 0) {
          newObj.layout.texts = newObj.layout.texts.map((t, index) => ({
            ...defaultConfig.layout.texts[index],
            ...t,
          }));
        }

        if (
          newObj.layout.groundImages.backgroundImage === '' ||
          !newObj.layout.groundImages.backgroundImage
        ) {
          newObj.layout.groundImages.backgroundImage =
            '/media/background-2.jpg';
        }
      }
      console.log('newObj: ', newObj);
      newObj = {id: newObj.id, name: newObj.name, ...newObj}; // just to have the id and name in the first position for readability
      setSelectedConf(newObj);
      setNewConfig(newObj);
    }
  }, [selectedDesignType]);

  const getDesignTypes = (token) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/admin/design/design-type?token=${token}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }
    )
      .then(async (res) => {
        if (res.status === 401) {
          setError('Invalid token');
        } else if (res.status !== 200) {
          setError('An error has occurred');
        } else {
          let dTypes = await res.json();
          setDesignTypes(dTypes);
          setError(undefined);
        }
      })
      .catch((e) => console.error(e));
  };

  return (
    <>
      {/* Background color split screen for large screens */}
      <div
        className="fixed top-0 left-0 h-full w-1/2 bg-white"
        aria-hidden="true"
      />
      <div
        className="fixed top-0 right-0 h-full w-1/2 bg-gray-50"
        aria-hidden="true"
      />
      <div className="relative flex min-h-screen flex-col">
        {/* Navbar */}
        <Header
          config={newConfig}
          onAuthenticate={(token) => getDesignTypes(token)}
        />

        {error && (
          <div className={'p-8'}>
            {Alert({type: 'error', title: 'Error', message: error})}
          </div>
        )}
        {/* 3 column wrapper */}
        <div className="mx-auto w-full max-w-full flex-grow lg:flex xl:px-0">
          {/* Left sidebar & main wrapper */}
          <div className="min-w-0 flex-1 bg-white xl:flex">
            <div className="border-b border-gray-200 bg-white xl:w-64 xl:flex-shrink-0 xl:border-b-0 xl:border-r xl:border-gray-200">
              <div className="h-full px-4 py-6">
                {/* Start left column area */}
                <div
                  className="relative flex h-full"
                  style={{minHeight: '12rem'}}>
                  <div className="absolute inset-0 rounded-lg border-2 border-dashed border-gray-200">
                    <div className={'h-full'}>
                      <Checkbox
                        id={'new-collage'}
                        name={'new-collage'}
                        label={'Display new collage'}
                        className={'m-4'}
                        checked={displayNewCollage}
                        onClick={() => setDisplayNewCollage(!displayNewCollage)}
                      />
                      <Checkbox
                        id={'old-collage-bellow'}
                        name={'old-collage-bellow'}
                        label={'Display old collage below'}
                        className={'m-4'}
                        checked={displayOldCollageBellow}
                        onClick={() =>
                          setDisplayOldCollageBellow(!displayOldCollageBellow)
                        }
                      />
                      <Checkbox
                        id={'old-collage-over'}
                        name={'old-collage-over'}
                        label={'Display old collage over'}
                        className={'m-4'}
                        checked={displayOldCollageOver}
                        onClick={() =>
                          setDisplayOldCollageOver(!displayOldCollageOver)
                        }
                      />
                      {displayOldCollageOver && (
                        <Slider
                          id="opacity"
                          label={'Opacity'}
                          value={opacity}
                          onChange={(e) => setOpacity(e.target.value)}
                        />
                      )}
                      <Dropdown
                        disabled={designTypes.length === 0}
                        options={designTypes}
                        onSelect={(selected) => {
                          setSelectedDesignType(selected);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* End left column area */}
              </div>
            </div>

            <div className="bg-white lg:min-w-0 lg:flex-1">
              <div className="h-full py-6 px-6 sm:px-6 lg:px-6">
                {/* Start main area*/}
                <div
                  className="relative flex h-full"
                  style={{minHeight: '36rem'}}>
                  <div className="absolute inset-0 rounded-lg border-2 border-dashed border-gray-200" />

                  <div className={'m-auto'}>
                    {selectedDesignType && displayOldCollageOver && (
                      <div className={'absolute top-0'}>
                        <KruuCollage2
                          className={`kruu-collage max-w-xl opacity-${opacity} invert`}
                          config={{
                            editorMode: true,
                            layout: generateLayoutForLegacyKruuCollage({
                              ...selectedDesignType,
                              hideBackground: true,
                            }),
                          }}
                          stageId={`kruu-collage-3`}
                          anonymousCrossOrigin={true}
                        />
                      </div>
                    )}
                    {displayNewCollage && (
                      <>
                        <KruuCollage
                          className={`mb-4 max-h-96 bg-white`}
                          id={'kruu-collage'}
                          key={Date.now()}
                          config={newConfig}
                        />
                      </>
                    )}
                    {selectedDesignType && displayOldCollageBellow && (
                      <>
                        Old collage:
                        <KruuCollage2
                          className={'kruu-collage max-w-xl'}
                          config={{
                            editorMode: true,
                            layout:
                              generateLayoutForLegacyKruuCollage(
                                selectedDesignType
                              ),
                          }}
                          stageId={`kruu-collage-1`}
                          anonymousCrossOrigin={true}
                        />
                      </>
                    )}
                  </div>
                </div>
                {/* End main area */}
              </div>
            </div>
          </div>

          <div className="bg-gray-50 px-2 sm:px-6 lg:flex-shrink-0 lg:border-l lg:border-gray-200 lg:px-2">
            <div className="h-full px-4 py-6 lg:w-100">
              {/* Start right column area */}
              <div
                className="relative flex h-full"
                style={{minHeight: '36rem'}}>
                <div className="absolute inset-0 rounded-lg border-2 border-dashed border-gray-200">
                  <JsonEditor
                    className={'h-full rounded-lg'}
                    json={selectedConf}
                    onChange={(json) => setNewConfig(json)}
                  />
                </div>
              </div>
              {/* End right column area */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
