export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const generateLayoutForLegacyKruuCollage = ({
  width,
  height,
  stripePrint,
  imagesConfig,
  fontPositions,
  printerPageSize,
  isPortrait,
  hideBackground,
}) => {
  const layout = {
    width,
    height,
    stripePrint,
    photos: [],
    printerPageSize,
    isPortrait,
  };
  if (hideBackground) {
    layout.backgroundImage = '';
  } else {
    layout.backgroundImage = '/media/background-2.jpg';
  }
  if (fontPositions.length > 0) {
    layout.texts = fontPositions.map((fp) => ({...fp}));
    layout.texts[0].content = 'Test & Test';
    layout.texts[0].sample = '';
    if (layout.texts[1]) {
      layout.texts[1].content = '2022.04.25';
      layout.texts[1].sample = '';
    }
  }

  imagesConfig.forEach((img, index) => {
    layout.photos.push({
      ...img,
      src: `media/image-${index + 1}.jpg`,
    });
  });

  return layout;
};

export const downloadConfig = (filename, config) => {
  let configCopy = {...config}; //Create a copy to avoid modifying the original object (by Ref)
  if (configCopy.layout && configCopy.layout.groundImages) {
    configCopy.layout.groundImages.backgroundImage = '';
    configCopy.layout.groundImages.foregroundImage = '';
  }
  if (
    configCopy.layout &&
    configCopy.layout.photos &&
    configCopy.layout.photos.length
  ) {
    configCopy.layout.photos.forEach((photo) => {
      photo.src = '';
    });
  }
  if (
    configCopy.layout &&
    configCopy.layout.texts &&
    configCopy.layout.texts.length
  ) {
    delete configCopy.layout.texts[0].fill;
    delete configCopy.layout.texts[0].fontSize;
    delete configCopy.layout.texts[0].fontStyle;
    delete configCopy.layout.texts[0].fontFamily;
    delete configCopy.layout.texts[0].fontAlignment;
    delete configCopy.layout.texts[0].content;
    delete configCopy.layout.texts[0].sample;
    if (configCopy.layout.texts[1]) {
      delete configCopy.layout.texts[1].fill;
      delete configCopy.layout.texts[1].fontSize;
      delete configCopy.layout.texts[1].fontStyle;
      delete configCopy.layout.texts[1].fontFamily;
      delete configCopy.layout.texts[1].fontAlignment;
      delete configCopy.layout.texts[1].content;
      delete configCopy.layout.texts[1].sample;
    }
  }

  const blob = new Blob([JSON.stringify(configCopy)], {type: 'text/json'});
  const link = document.createElement('a');

  link.download = filename;
  link.href = window.URL.createObjectURL(blob);
  link.dataset.downloadurl = ['text/json', link.download, link.href].join(':');

  const evt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  link.dispatchEvent(evt);
  link.remove();
};
