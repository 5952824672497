import {Fragment, useEffect, useState} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/solid';
import {classNames} from '../utils';

const Dropdown = ({options, onSelect, disabled}) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (options.length && !selected) {
      setSelected(options[0]);
      onSelect(options[0]);
    }
  }, [options]);

  return (
    <Menu as="div" className=" inline-block flex max-h-36 w-full text-left">
      <div className={'w-full p-4'}>
        <Menu.Button
          disabled={disabled}
          className="flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-rose-300 focus:ring-offset-2 focus:ring-offset-gray-100">
          {selected ? selected.name : 'Design Types'}
          <ChevronDownIcon className="ml-auto  h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute right-0 mt-2 max-h-96 w-56 origin-top-right overflow-y-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map((option, idx) => {
              return (
                <Menu.Item
                  key={`menu-item-${idx}`}
                  onClick={() => {
                    setSelected(option);
                    onSelect(option);
                  }}>
                  {({active}) => (
                    <span
                      key={`menu-item-span-${options.id}`}
                      className={classNames(
                        active ? 'bg-rose-200 text-white' : 'bg-white',
                        'block px-4 py-2 text-sm'
                      )}>
                      {option.name}
                    </span>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
