/* This example requires Tailwind CSS v2.0+ */
import {CheckCircleIcon, XCircleIcon} from '@heroicons/react/solid'

export default function Alert({type, title, message}) {
  let bg = type === 'error' ? 'bg-red-50' : 'bg-green-50';
  let text =  type === 'error' ? 'text-red' : 'text-green';
  return (
    <div className={`rounded-md ${bg} p-4`}>
      <div className={`flex`}>
        <div className="flex-shrink-0">
          {
            type === 'error' ?
            <XCircleIcon className={`h-5 w-5 text-red-400`} aria-hidden="true"/> :
            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
          }
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium ${text}-800`}>{title}</h3>
          <div className={`mt-2 text-sm ${text}-700`}>
            <ul role="list" className="list-disc pl-5 space-y-1">
              <li>{message}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}