import React, {useEffect} from 'react';


const JsonEditor = ({className, onChange, json}) => {
  function syntaxHighlight(json) {
    if (typeof json != 'string') {
      json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function (match) {
      let cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    });
  }

  return (
    <>
      <div className={`${className} editor`} contentEditable={true}
               onInput={(e) => {
                 try {
                   const json = JSON.parse(e.currentTarget.textContent);
                   onChange(json);
                 } catch (e) {}
               }}
               dangerouslySetInnerHTML={{__html: syntaxHighlight(JSON.stringify(json, 0, 4))}}
      />
    </>
  );
};

JsonEditor.propTypes = {

};

export default JsonEditor;