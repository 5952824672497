import React, {useCallback, useEffect, useState} from 'react';
import {Disclosure} from "@headlessui/react";
import {downloadConfig} from "../utils";
import {KeyIcon} from "@heroicons/react/solid";
import {debounce} from 'throttle-debounce';

const Header = ({config, onAuthenticate}) => {
  const [token, setToken] = useState(localStorage.getItem('token') ||'');

  const debouncedToken = useCallback(debounce(1000, (token) => {
    localStorage.setItem('token', token);
    onAuthenticate(token);
  }), []);
  useEffect(() => {
    debouncedToken(token)
  }, [token]);

  return (
    <Disclosure as="nav" className="flex-shrink-0 bg-rose-200">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              {/* Logo section */}
              <div className="flex items-center px-2 lg:px-0 xl:w-64">
                <div className="flex-shrink-0">
                  <img
                    className="h-8 w-auto"
                    src="/media/kruu-logo.svg"
                    alt="Workflow"
                  />
                </div>
              </div>

              {/* Search section */}
              <div className="flex-1 flex justify-center lg:justify-end">
                <div className="w-full px-2 lg:px-6">
                  <label htmlFor="token" className="sr-only">
                    Token
                  </label>
                  <div className="relative text-white focus-within:text-white">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <KeyIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="token"
                      name="token"
                      className="block w-full pl-10 pr-3 py-2 border border-white rounded-md leading-5 bg-rose-400 bg-opacity-25 text-rose-100 placeholder-white focus:border-white focus:outline-none focus:bg-rose-300 focus:ring-0 focus:ring-rose-400 focus:placeholder-white focus:text-white sm:text-sm"
                      placeholder="Token"
                      type="password"
                      value={token}
                      onChange={(e) => setToken(e.target.value)}
                    />
                    {/*<button className={'rounded'} onClick={() =>  onSetToken(token)} />*/}
                  </div>
                </div>
              </div>
              {/* Links section */}
              <div className="block lg:block sm:flex sm:w-auto p-4 lg:w-80">
                <div className="flex items-center justify-end">
                  <div className="flex">
                    <button
                      onClick={() => downloadConfig(`${config.id}-${config.name}.json`,config)}
                      className="bg-white opacity-1 px-3 py-2 rounded-md text-sm font-medium text-rose-300 hover:text-rose-400 hover:opacity-60"
                    >
                      Download file
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
};

Header.propTypes = {

};

export default Header;